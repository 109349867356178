import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DateSelect from "../components/DateSelect";
import Button from "@material-ui/core/Button";
import 'date-fns';
import ScopeControlReportTable from "../components/ScopeControlReportTable";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function ScopeControlReportPage({match}) {
    const classes = useStyles();
    const {
        params: {id_sc},
    } = match;

    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth()-2);
    fromDateInitial.setDate(1);
    const tillDateInitial = new Date();

    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [tillDate, setTillDate] = useState(tillDateInitial);

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);


    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    const openReport = (id_ms) => {
        flaskAPI.get('/api/report/sc/single-report/' + id_ms, {
            method: "GET",
            responseType: "blob",
        }).then(response => {
            const file = new Blob([response.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(error => {
            setLoading(false);
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
        });
    };

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        console.log(id_sc)
        flaskAPI.get('/api/report/sc/measurement', {
            params: {
                id_sc: id_sc,
                from_date: fromDate ? fromDate : '',
                till_date: tillDate ? tillDate : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, [id_sc]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {/* Column */}
                    <Grid container spacing={2} direction="column">
                        {/* SC */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <DateSelect
                                    error={fromDateError}
                                    inputLabel="Date from"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateSelect
                                    error={tillDateError}
                                    inputLabel="Date till"
                                    value={tillDate}
                                    onChange={handleTillDateChange}
                                />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary"
                                        disabled={fromDateError || tillDateError}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ScopeControlReportTable data={data} loading={loading} openReport={openReport} />
            </Grid>
        </Grid>

    );
}