import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function WASDButtons(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid
                    container
                    item
                direction="column"
                spacing={10}
                alignItems="center"
                    justifyContent="center"
                    xs={6}
            >
                <Grid item>
                    <Button variant="contained" disabled={props.disabled} onClick={() => props.onWASD('w')} color="secondary">
                        Nothing
                    </Button>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={10}
                >
                    <Grid item>
                        <Button variant="contained" disabled={props.disabled} onClick={() => props.onWASD('a')}>
                        Particles
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={props.disabled} onClick={() => props.onWASD('s')}>
                        Both
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={props.disabled} onClick={() => props.onWASD('d')}>
                        Broken
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Button variant="contained" disabled={props.disabled} onClick={() => props.onWASD('u')}>
                        Unclear
                </Button>
            </Grid>
            </Grid>
        </div>
    );
}
