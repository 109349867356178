import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateSelect from "../components/DateSelect";
import Button from "@material-ui/core/Button";
import SearchAuto from "../components/SearchAuto";
import 'date-fns';
import LightControlRawDataTable from "../components/LightControlRawDataTable";
import LightControlCalibrationTable from "../components/LightControlCalibrationTable";
import {useLocalStorage} from "../context/useLocalStorage";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function CalibrationPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const [selectedLc, setSelectedLc] =  useLocalStorage("calibrationPageSelectedLc", null)

    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth());
    fromDateInitial.setDate(1);
    const today = new Date();
    today.setHours(today.getHours() + 24);
    const tillDateInitial = today;

    const [fromDate, setFromDate] = useLocalStorage("calibrationPageFromDate", fromDateInitial);
    const [tillDate, setTillDate] = useLocalStorage("calibrationPageTillDate", tillDateInitial);

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);


    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        flaskAPI.get('/api/data/lc/calibration', {
            params: {
                id_lc: selectedLc? selectedLc.id_lc : '',
                from_date: fromDate ? fromDate : '',
                till_date: tillDate ? tillDate : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {/* Column */}
                    <Grid container spacing={2} direction="column">
                        {/* Org -> LC */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="serialnumber"
                                    inputLabel="Select LightControl"
                                    noOptionsText="No LightControls"
                                    value={selectedLc}
                                    onChange={setSelectedLc}
                                    apiUrl={"/api/data/lc/search"}
                                />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <DateSelect
                                    error={fromDateError}
                                    inputLabel="Date from"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateSelect
                                    error={tillDateError}
                                    inputLabel="Date till"
                                    value={tillDate}
                                    onChange={handleTillDateChange}
                                />
                            </Grid>
                        </Grid>

                        {/* Brand -> Type -> Serial */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary"
                                        disabled={fromDateError || tillDateError}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <LightControlCalibrationTable
                    data={data}
                    loading={loading}
                />
            </Grid>
        </Grid>

    );
}