import React, {useEffect, useState} from 'react';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';
import {tableIcons} from "../styles";
import {formatFloat} from "../helpers";
import ImagesDialog from "./ImagesDialog";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { CsvBuilder } from "filefy";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function LightControlCalibrationTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [selectedIdLms, setSelectedIdLms] = useState();
    const [images, setImages] = useState([]);

    const columns = [
        { title: 'id_lms', field: 'id_lms', type: 'numeric', defaultSort: 'desc'},
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime)?.tz('Etc/GMT')?.format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        { title: 'lightcontrol', field: 'lightcontrol.serialnumber', },
        { title: 'serial', field: 'endoscope_serial.serialnumber', },
        { title: 'ltuncorrected', field: 'ltuncorrected', type: 'numeric', render: rowData => formatFloat(rowData.ltuncorrected) },
        { title: 'rx/tx', type: 'numeric', render: rowData => formatFloat(rowData.rx / rowData.tx) },
    ];

    const handlePlot = (evt, data) => {
        flaskAPI.get('/api/data/lc/plot', {
            params: {
                id_lms_values: data.map(a => a.id_lms),
            },
            method: "GET",
            responseType: "blob",
        }).then(response => {
            const file = new Blob([response.data], {
                type: "image/png",
            });
            const fileURL = URL.createObjectURL(file);
            var newWindow = window.open(fileURL);
            // setTimeout(function(){ newWindow.document.title = 'my new title'; }, 1000);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
        });
    }

    const handleExport = (evt, data) => {
        // Define the columns you want to export
        const columns = [
            { title: 'id_lms', field: 'id_lms' },
            { title: 'datetime', field: 'datetime' },
            { title: 'lightcontrol.serialnumber', field: 'lightcontrol.serialnumber' },
            { title: 'endoscope_serial.serialnumber', field: 'endoscope_serial.serialnumber' },
            { title: 'ltuncorrected', field: 'ltuncorrected' },
            { title: 'rx/tx', field: 'rx/tx' }, // This will be calculated manually
            { title: 'rx', field: 'rx' },
            { title: 'tx', field: 'tx' }
        ];

        // Transform the data to include the 'rx/tx' calculation
        const transformedData = data.map(item => ({
            id_lms: item.id_lms,
            datetime: item.datetime,
            'lightcontrol.serialnumber': item.lightcontrol.serialnumber,
            'endoscope_serial.serialnumber': item.endoscope_serial.serialnumber,
            ltuncorrected: item.ltuncorrected,
            'rx/tx': item.rx / item.tx, // Calculate 'rx/tx'
            rx: item.rx,
            tx: item.tx
        }));

        // Extract the column titles for the CSV header
        const columnTitles = columns.map(column => column.title);

        // Extract the row data for the CSV
        const rowData = transformedData.map(item => columns.map(column => item[column.field]));

        // Create and download the CSV file
        new CsvBuilder('export.csv')
            .setColumns(columnTitles)
            .addRows(rowData)
            .exportFile();
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="LC - Raw measurements"
                columns={columns}
                data={props.data}
                options={{
                    selection: true,
                    padding: "dense",
                    search: true,
                    sorting: true,
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
                actions={[
                    {
                        icon: () => <ShowChartIcon />,
                        tooltip: 'Generate plot',
                        onClick: handlePlot
                    },
                    {
                        position: "toolbarOnSelect",
                        icon: tableIcons.Export,
                        tooltip: 'Export the selected rows',
                        onClick: handleExport
                    }
                ]}
            />
        </>
    );
}

export default withStyles(styles)(LightControlCalibrationTable);
