import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateSelect from "../components/DateSelect";
import Button from "@material-ui/core/Button";
import SearchAuto from "../components/SearchAuto";
import 'date-fns';
import LightControlRawDataTable from "../components/LightControlRawDataTable";
import {useLocalStorage} from "../context/useLocalStorage";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function LightControlDataPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const [selectedOrg, setSelectedOrg] =  useLocalStorage("lightControlDataPageSelectedOrg", null)
    const [selectedLc, setSelectedLc] = useLocalStorage("lightControlDataPageSelectedLc", null)
    const [selectedBrand, setSelectedBrand] = useLocalStorage("lightControlDataPageSelectedBrand", null)
    const [selectedMsl, setSelectedMsl] = useLocalStorage("lightControlDataPageSelectedMsl", null)
    const [selectedSerial, setSelectedSerial] = useLocalStorage("lightControlDataPageSelectedSerial", null)

    const [reRenderScope, setReRenderScope] = useState(0)
    const [reRenderType, setReRenderType] = useState(0)
    const [reRenderSerial, setReRenderSerial] = useState(0)

    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth());
    fromDateInitial.setDate(1);
    const today = new Date();
    today.setHours(today.getHours() + 24);
    const tillDateInitial = today;

    const [fromDate, setFromDate] = useLocalStorage("lightControlDataPageFromDate", fromDateInitial);
    const [tillDate, setTillDate] = useLocalStorage("lightControlDataPageTillDate", tillDateInitial);

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);


    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        flaskAPI.get('/api/data/lc/measurement', {
            params: {
                id_org: selectedOrg ? selectedOrg.id_org : '',
                id_lc: selectedLc? selectedLc.id_lc : '',
                id_brand: selectedBrand ? selectedBrand.id_brand : '',
                id_msl: selectedMsl ? selectedMsl.id_msl : '',
                id_es: selectedSerial ? selectedSerial.id_es : '',
                from_date: fromDate ? fromDate : '',
                till_date: tillDate ? tillDate : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, []);

    // Reset ES serial when brand or type changes
    useEffect(() => {
        setReRenderSerial(reRenderSerial+1)
    }, [selectedBrand, selectedMsl]);

    const openReport = () => {
        setLoading(true)
        flaskAPI.get('/api/report/lc/multi-report', {
            params: {
                id_org: selectedOrg ? selectedOrg.id_org : '',
                id_lc: selectedLc? selectedLc.id_lc : '',
                id_brand: selectedBrand ? selectedBrand.id_brand : '',
                id_msl: selectedMsl ? selectedMsl.id_msl : '',
                id_es: selectedSerial ? selectedSerial.id_es : '',
                from_date: fromDate ? fromDate : '',
                till_date: tillDate ? tillDate : '',
            },
            method: "GET",
            responseType: "blob",
        }).then(response => {
            setLoading(false);
            const file = new Blob([response.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(error => {
            setLoading(false);
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {/* Column */}
                    <Grid container spacing={2} direction="column">
                        {/* Org -> LC */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="org_name"
                                    inputLabel="Select organization"
                                    noOptionsText="No organizations"
                                    value={selectedOrg}
                                    onChange={(newVal) => {
                                        setSelectedOrg(newVal)
                                        setSelectedLc(null)

                                        setReRenderScope(reRenderScope+1)
                                    }}
                                    apiUrl="/api/data/org/search"
                                />
                            </Grid>


                            <Grid item> <ArrowForwardIcon /> </Grid>

                            <Grid item>
                                <SearchAuto
                                    optionLabelName="serialnumber"
                                    key={reRenderScope}
                                    inputLabel="Select LightControl"
                                    noOptionsText="No LightControls"
                                    value={selectedLc}
                                    onChange={setSelectedLc}
                                    apiUrl={!selectedOrg ? "/api/data/lc/search" : ("/api/data/lc/search?id_org=" + selectedOrg.id_org)}
                                />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <DateSelect
                                    error={fromDateError}
                                    inputLabel="Date from"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateSelect
                                    error={tillDateError}
                                    inputLabel="Date till"
                                    value={tillDate}
                                    onChange={handleTillDateChange}
                                />
                            </Grid>
                        </Grid>

                        {/* Brand -> Type -> Serial */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="brand"

                                    inputLabel="Select brand"
                                    noOptionsText="No brands"
                                    value={selectedBrand}
                                    onChange={(newVal) => {
                                        setSelectedBrand(newVal)
                                        setSelectedMsl(null)
                                        setSelectedSerial(null)

                                        setReRenderType(reRenderType+1)
                                        setReRenderSerial(reRenderSerial+1)
                                    }}

                                    apiUrl="/api/data/endoscope/brand/search"
                                />
                            </Grid>
                            <Grid item> <ArrowForwardIcon /> </Grid>
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="scopetype"
                                    key={reRenderType} // Update when brand changes
                                    inputLabel="Select type"
                                    noOptionsText="No types"
                                    value={selectedMsl}
                                    onChange={(newVal) => {
                                        setSelectedMsl(newVal)
                                        setSelectedSerial(null)

                                        setReRenderSerial(reRenderSerial+1)
                                    }}
                                    apiUrl={!selectedBrand ? "/api/data/msl/search" : ("/api/data/msl/search?id_brand=" + selectedBrand.id_brand)}
                                />
                            </Grid>
                            <Grid item> <ArrowForwardIcon /> </Grid>
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="serialnumber"
                                    key={reRenderSerial}
                                    inputLabel="Select serial"
                                    noOptionsText="No serials"
                                    value={selectedSerial}
                                    onChange={setSelectedSerial}
                                    apiUrl={!selectedMsl ? "/api/data/endoscope/serial/search" : ("/api/data/endoscope/serial/search?id_msl=" + selectedMsl.id_msl)}
                                />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary"
                                        disabled={fromDateError || tillDateError}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <LightControlRawDataTable
                    data={data}
                    loading={loading}
                    onOpenReport={openReport}
                />
            </Grid>
        </Grid>

    );
}