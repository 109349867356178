import locale from 'date-fns/locale/nl'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';


export default function DateSelect(props) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <KeyboardDatePicker
                error={props.error}
                variant="inline"
                label={props.inputLabel}
                style={{width: 250}}
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={props.value}
                onChange={props.onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                    'tabIndex': '3'
                }}
                autoFocus
                inputVariant="outlined"
            />
        </MuiPickersUtilsProvider>
    );
}