import React, {useEffect, useState} from 'react';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';
import {tableIcons} from "../styles";
import {formatFloat} from "../helpers";
import ImagesDialog from "./ImagesDialog";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function LeakControlRawDataTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();

    const columns = [
        { title: 'id_fms', field: 'id_fms', type: 'numeric', defaultSort: 'desc'},
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime)?.tz('Etc/GMT')?.format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        { title: 'leakcontrol', field: 'leakcontrol.serialnumber', },
        { title: 'serial', field: 'flexible_endoscope.serialnumber', },
        { title: 'datamatrix', field: 'flexible_endoscope.datamatrixcode', },
        { title: 'p05', field: 'p05', type: 'numeric' },
        { title: 'p25', field: 'p25', type: 'numeric' },
        { title: 'p50', field: 'p50', type: 'numeric' },
        { title: 'p75', field: 'p75', type: 'numeric' },
        { title: 'p95', field: 'p95', type: 'numeric' },
        { title: 'cycle_used', field: 'cycle_used', type: 'numeric' },
        { title: 'result', field: 'result' },
        { title: 'time_settings', field: 'time_settings' },
        { title: 'deviation', field: 'devation', type: 'numeric' },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="LC - Raw measurements"
                columns={columns}
                data={props.data}
                options={{
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
            />
        </>
    );
}

export default withStyles(styles)(LeakControlRawDataTable);
