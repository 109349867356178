import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function TrafficLightButtons(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={3}
                xs={6}
            >
                    <Grid item>
                        <Button
                            style={{ backgroundColor: "#8bc34a", }}
                            variant="contained" disabled={props.disabled} onClick={() => props.onClick('green')}>
                            pass
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            style={{ backgroundColor: "#ff9800", }}
                            variant="contained" disabled={props.disabled} onClick={() => props.onClick('orange')}>
                            danger / doubt
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            style={{ backgroundColor: "#ff1744" }}
                            variant="contained" disabled={props.disabled} onClick={() => props.onClick('red')}>
                            fail
                        </Button>
                    </Grid>
                {/*<Grid item style={{ flexGrow: "1" }} />*/}
                <Grid item>
                    <Button variant="contained" disabled={props.disabled} onClick={() => props.onClick('unclear_or_wrong')}>
                        Unclear / Wrong
                </Button>
                </Grid>
            </Grid>
        </div>
    );
}
