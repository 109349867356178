import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Title from "../components/Title";
import WASDButtons from "../components/WASDButtons";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 250.0,
    },
}));

export default function LabelPage(props) {
    const classes = useStyles();

    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("")
    const [image, setImage] = useState({})
    const [reload, setReload] = useState(0)

    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        console.log(`Nothing: ${event.key}`);
        handleCreate(event.key)
    }, [reload]);

    const keyToLabel = (key) => {
        switch(key) {
            case 'w':
                return "nothing"
                break;
            case 'a':
                return "particles"
                break;
            case 's':
                return "both"
                break;
            case 'd':
                return "broken"
                break;
            case 'u':
                return "unclear"
            default:
                return ''
        }
    }

    /*useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);*/

    const handleCreate = (key) => {
        const label = keyToLabel(key);
        let newData = {};
        if(label != '' && image && image.id_si) {
            newData =  { 'fk_id_si': image.id_si, 'label': label }
            flaskAPI.post(props.apiCreateUrl, newData).then(response => {
                if (response.status === 201) {
                    setReload(reload+1)
                }
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        } else {
        }
    }

    useEffect(() => {
        setLoading(true);
        setImageUrl("")
        setImage({})
        flaskAPI.get(props.apiGetUrl).then(response => {
            if (response.status === 200) {
                setImageUrl(response.data.url)
                setImage(response.data.image)
            } else {
                // TODO error message
            }
            setLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
            setLoading(false);
        });
    }, [props.apiGetUrl, reload, setAuthTokens]);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.fixedHeight2} xs={12}>
                    <Title>{props.title}</Title>
                    <Grid container item direction="row" xs={12} spacing={4}>
                        <Grid item xs={8}>
                        <WASDButtons disabled={!imageUrl} onWASD={(key) => handleCreate(key)} />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={3}>
                            <Typography variant="h6" component="h6" color="primary" gutterBottom>
                                Instructions
                            </Typography>
                            In this menu you only need to detect whether you see <i>nothing</i>, <i>particles</i>, <i>fracture</i> or <i>both</i>.
                            You do not need to bother about the amount or gradation. In case of doubt or no image, press <i>unclear</i>.
                            In case of hairs, moisture, air, etc, press <i>particles</i>.
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={6}>
                <Paper className={classes.paper} xs={6}>
                    <img src={imageUrl} />
                </Paper>
            </Grid>
        </Grid>
    );
}