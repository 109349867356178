import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";


const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    }
});

const columns = [
    {
        title: 'Date',
        field: 'date',
        type: 'date',
        dateSetting: { locale: "nl-NL"},
        defaultSort: 'desc'
    },
    {
        title: 'Serial',
        field: 'serial',
    },
    {
        title: 'Client',
        field: 'org',
    },
    {
        title: 'Comment',
        field: 'comment',
    },
    {
        title: 'Incoming',
        field: 'is_incoming',
        type: 'boolean',
    },
];


function ShippingLogsTable(props) {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/shipping-logs')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);



    const handleAdd = (newData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (newData) {
                    flaskAPI.post('/api/shipping-logs', newData).then(response => {
                        if (response.status === 201) {
                            setData([...data, response.data]);
                            resolve();
                        } else {
                            reject(response.data);
                        }
                    }).catch(error => {
                        reject(error);
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 400)
        });
    }

    const handleUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.put('/api/shipping-logs/' + oldData.id, newData).then(response => {
                        if (response.status === 200) {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 400)
        });
    }

    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.delete('/api/shipping-logs/' + oldData.id).then(response => {
                        if (response.status === 200) {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setData([...dataDelete]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                }
            }, 400)
        });
    }

    return (
        <>
            <MaterialTable
                localization={{
                    header: {
                        actions: ''
                    }
                }}
                icons={tableIcons}
                isLoading={props.loading || loading}
                title="Shipping logs"
                columns={columns}
                data={data}
                options={{
                    addRowPosition: 'first',
                    pageSize: 12,
                    emptyRowsWhenPaging: true,
                    pageSizeOptions: [12, 20, 50, 100],
                    sorting: true,
                    actionsColumnIndex: -1
                }}
                editable={{
                    onRowAdd: handleAdd,
                    onRowUpdate: handleUpdate,
                    onRowDelete: handleDelete,
                }}
            />
        </>
    );
}

export default withStyles(styles)(ShippingLogsTable);
