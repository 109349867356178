import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Title from "../components/Title";
import WASDButtons from "../components/WASDButtons";
import TrafficLightButtons from "../components/TrafficLightButtons";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 250.0,
    },
}));

export default function SubLabelPage(props) {
    const classes = useStyles();

    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("")
    const [imageLabel, setImageLabel] = useState({})
    const [reload, setReload] = useState(0)

    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        console.log(`Nothing: ${event.key}`);
        handleCreate(event.key)
    }, [reload]);


    const handleCreate = (key) => {
        const label = key;
        let newData = {};
        if(label != '' && imageLabel && imageLabel.id_il) {
            newData =  { 'fk_id_il': imageLabel.id_il, 'sub_label': label }
            flaskAPI.post(props.apiCreateUrl, newData).then(response => {
                if (response.status === 201) {
                    setReload(reload+1)
                }
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        } else {
        }
    }

    useEffect(() => {
        setLoading(true);
        setImageUrl("")
        setImageLabel({})
        flaskAPI.get(props.apiGetUrl).then(response => {
            if (response.status === 200) {
                setImageUrl(response.data.url)
                setImageLabel(response.data.image_label)
            } else {
                // TODO error message
            }
            setLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
            setLoading(false);
        });
    }, [props.apiGetUrl, reload, setAuthTokens]);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {/* Time Window */}
                <Paper className={classes.fixedHeight2} xs={12}>
                    <Title>{props.title}</Title>
                    <Grid container item direction="row" xs={12} spacing={3}>
                        <Grid item xs={6}>
                        <TrafficLightButtons disabled={!imageUrl} onClick={(key) => handleCreate(key)} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" component="h6" color="primary" gutterBottom>
                                Instructions
                            </Typography>
                            In this menu you will be able to classify the images with particles that have been selected in the other menu.
                            Press <i>pass</i> in case there are hardly any particles and you believe it will not affect its performance
                            Press <i>danger / doubt</i> in case there are a significant amount of particles, but you doubt it will affect its performance. the particle can be a natural presence of internal debris caused by sterilization
                            Press <i>fail</i> when it is too obvious and you cannot imagine a doctor would like to use it. Press also <i>red</i> in case of moist, hair, clear intrusion from the outside that could cause contamination/infection when reused
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={6}>
                <Paper className={classes.paper} xs={6}>
                    <img src={imageUrl} />
                </Paper>
            </Grid>
        </Grid>
    );
}