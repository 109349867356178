import React, {useEffect, useState} from 'react';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {tableIcons} from "../styles";
import {formatFloat, formatInt} from "../helpers";
import GcLtDialog from "./GcLtDialog";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function GuideControlRawDataTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingLt, setLoadingLt] = useState(false);
    const [selectedIdMs, setSelectedIdMs] = useState();
    const [ltObj, setLtObj] = useState({});

    const handleClickOpen = (rowData) => {
        setSelectedIdMs(rowData.id_ms);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(selectedIdMs) {
            setLoadingLt(true)
            setLtObj({});
            flaskAPI.get("/api/data/gc/lt/" + selectedIdMs).then(response => {
                if (response.status === 200) {
                    setLtObj(response.data);
                    setLoadingLt(false)
                } else {
                    // TODO error message
                }
            })
                .catch(error => {
                    setLoadingLt(false)
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [selectedIdMs, setAuthTokens]);


    const columns = [
        { title: 'id_ms', field: 'id_ms', type: 'numeric', defaultSort: 'desc'},
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime).format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        { title: 'guidecontrol', field: 'guidecontrol.serialnumber' },
        { title: 'scanner_source', field: 'scanner_source' },
        { title: 'scanner_message', field: 'scanner_message' },
        { title: 'fbrejection', field: 'fbrejection', type: 'numeric', render: rowData => formatInt(rowData.fbrejection) },
        { title: 'fbthreshold', field: 'fbthreshold', type: 'numeric', render: rowData => formatInt(rowData.fbthreshold) },
        { title: 'fbdiameter', field: 'fbrdiameter', type: 'numeric', render: rowData => formatFloat(rowData.fbdiameter) },
        { title: 'fboriginalvalue', field: 'fbroriginalvalue', type: 'numeric', render: rowData => formatInt(rowData.fboriginalvalue) },
        { title: 'fb_uncorrected', field: 'fb_uncorrected', type: 'numeric', render: rowData => formatFloat(rowData.fb_uncorrected) },
        { title: 'fb_reference', field: 'fb_reference', type: 'numeric', render: rowData => formatInt(rowData.fb_reference) },
        { title: 'fbresult', field: 'fbresult', type: 'numeric', render: rowData => formatInt(rowData.fbresult) },
        { title: 'ccthreshold', field: 'ccthreshold', type: 'numeric', render: rowData => formatInt(rowData.ccthreshold) },
        { title: 'ccred', field: 'ccred', type: 'numeric', render: rowData => formatInt(rowData.ccred) },
        { title: 'ccred_uncorrected', field: 'ccred_uncorrected', type: 'numeric', render: rowData => formatFloat(rowData.ccred_uncorrected) },
        { title: 'ccred_reference', field: 'ccred_reference', type: 'numeric', render: rowData => formatInt(rowData.ccred_reference) },
        { title: 'ccgreen', field: 'ccgreen', type: 'numeric', render: rowData => formatInt(rowData.ccgreen) },
        { title: 'ccgreen_uncorrected', field: 'ccgreen_uncorrected', type: 'numeric', render: rowData => formatFloat(rowData.ccgreen_uncorrected) },
        { title: 'ccgreen_reference', field: 'ccgreen_reference', type: 'numeric', render: rowData => formatInt(rowData.ccgreen_reference) },
        { title: 'ccblue', field: 'ccblue', type: 'numeric', render: rowData => formatInt(rowData.ccblue) },
        { title: 'ccblue_uncorrected', field: 'ccblue_uncorrected', type: 'numeric', render: rowData => formatFloat(rowData.ccblue_uncorrected) },
        { title: 'ccblue_reference', field: 'ccblue_reference', type: 'numeric', render: rowData => formatInt(rowData.ccblue_reference) },
        { title: 'ccoriginalvalue', field: 'ccoriginalvalue', type: 'numeric', render: rowData => formatInt(rowData.ccoriginalvalue) },
        { title: 'ccresult', field: 'ccresult', type: 'numeric', render: rowData => formatInt(rowData.ccresult) },
        { title: 'ltthreshold', field: 'ltthreshold', type: 'numeric', render: rowData => formatInt(rowData.ltthreshold) },
        { title: 'ltoriginalvalue', field: 'ltoriginalvalue', type: 'numeric', render: rowData => formatInt(rowData.ltoriginalvalue) },
        { title: 'lt_uncorrected', field: 'lt_uncorrected', type: 'numeric', render: rowData => formatFloat(rowData.lt_uncorrected) },
        { title: 'lt_reference', field: 'lt_reference', type: 'numeric', render: rowData => formatInt(rowData.lt_reference) },
        { title: 'ltresult', field: 'ltresult', type: 'numeric', render: rowData => formatInt(rowData.ltresult) },
        { title: 'finalresult', field: 'finalresult' },
        { title: 'tx_red', field: 'tx_red', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_red) : 0 },
        { title: 'tx_green', field: 'tx_green', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_green) : 0},
        { title: 'tx_blue', field: 'tx_blue', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_blue) : 0},
        { title: 'tx_white', field: 'tx_white', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_white) : 0},
        { title: 'rx_red', field: 'rx_red', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_red) : 0},
        { title: 'rx_green', field: 'rx_green', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_green) : 0},
        { title: 'rx_blue', field: 'rx_blue', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_blue) : 0},
        { title: 'rx_white', field: 'rx_white', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_white) : 0},
        { title: 'tx_red_dl', field: 'tx_red_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_red_dl) : 0},
        { title: 'tx_green_dl', field: 'tx_green_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_green_dl) : 0},
        { title: 'tx_blue_dl', field: 'tx_blue_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_blue_dl) : 0},
        { title: 'tx_white_dl', field: 'tx_white_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_white_dl) : 0},
        { title: 'rx_red_dl', field: 'rx_red_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_red_dl) : 0},
        { title: 'rx_green_dl', field: 'rx_green_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_green_dl) : 0},
        { title: 'rx_blue_dl', field: 'rx_blue_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_blue_dl) : 0},
        { title: 'rx_white_dl', field: 'rx_white_dl', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_white_dl) : 0},
        { title: 'tx_red_lr', field: 'tx_red_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_red_lr) : 0},
        { title: 'tx_green_lr', field: 'tx_green_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_green_lr) : 0},
        { title: 'tx_blue_lr', field: 'tx_blue_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_blue_lr) : 0},
        { title: 'tx_white_lr', field: 'tx_white_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].tx_white_lr) : 0},
        { title: 'rx_red_lr', field: 'rx_red_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_red_lr) : 0},
        { title: 'rx_green_lr', field: 'rx_green_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_green_lr) : 0},
        { title: 'rx_blue_lr', field: 'rx_blue_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_blue_lr) : 0},
        { title: 'rx_white_lr', field: 'rx_white_lr', render: rowData => rowData?.raw.length ? formatInt(rowData?.raw[0].rx_white_lr) : 0},
    ];
    return (
        <>
            <GcLtDialog selectedLtObj={ltObj} open={open} onClose={handleClose} />
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="GC - Raw measurements"
                columns={columns}
                data={props.data}
                onRowClick={(event, rowData, togglePanel) => handleClickOpen(rowData)}
                options={{
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
            />
        </>
    );
}

export default withStyles(styles)(GuideControlRawDataTable);