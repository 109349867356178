import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {flaskAPI} from "../flaskAPI";
import {useAuth} from "../context/auth";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


export default function LabelRankingPage(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [rows, setRows] = useState([])


    useEffect(() => {
        // setLoading(true);
        setRows([])

        flaskAPI.get('/api/label/ranking').then(response => {
            if (response.status === 200) {
                setRows(response.data)
            } else {
                // TODO error message
            }
            // setLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
            // setLoading(false);
        });
    }, [setAuthTokens]);

    const summedTotals = rows ? rows.reduce((totals, { nothing, particles, both, broken, unclear, total }) => {
        totals['nothing'] += nothing;
        totals['particles'] += particles;
        totals['both'] += both;
        totals['broken'] += broken;
        totals['unclear'] += unclear;
        totals['total'] += total;
        return totals;
    }, {
        'nothing': 0,
        'particles': 0,
        'both': 0,
        'broken': 0,
        'unclear': 0,
        'total': 0,
    }) : {
        'nothing': 0,
        'particles': 0,
        'both': 0,
        'broken': 0,
        'unclear': 0,
        'total': 0,
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>User</TableCell>
                        <TableCell align="right">Nothing</TableCell>
                        <TableCell align="right">Particles</TableCell>
                        <TableCell align="right">Both</TableCell>
                        <TableCell align="right">Broken</TableCell>
                        <TableCell align="right">Unclear</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={row.username}>
                            <TableCell>{index+1}. </TableCell>
                            <TableCell component="th" scope="row"> {row.username} </TableCell>
                            <TableCell align="right">{row.nothing}</TableCell>
                            <TableCell align="right">{row.particles}</TableCell>
                            <TableCell align="right">{row.both}</TableCell>
                            <TableCell align="right">{row.broken}</TableCell>
                            <TableCell align="right">{row.unclear}</TableCell>
                            <TableCell align="right">{row.total}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow key="totals">
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right"><b>{summedTotals.nothing}</b></TableCell>
                        <TableCell align="right"><b>{summedTotals.particles}</b></TableCell>
                        <TableCell align="right"><b>{summedTotals.both}</b></TableCell>
                        <TableCell align="right"><b>{summedTotals.broken}</b></TableCell>
                        <TableCell align="right"><b>{summedTotals.unclear}</b></TableCell>
                        <TableCell align="right"><b>{summedTotals.total}</b></TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
