import React, {useState} from 'react';
import {useAuth} from "../context/auth";
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';
import {tableIcons} from "../styles";
import {formatFloat} from "../helpers";
import ImagesDialog from "./ImagesDialog";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function ScopeControlReportTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [selectedIdLms, setSelectedIdLms] = useState();
    const [images, setImages] = useState([]);

    const columns = [
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime)?.tz('Etc/GMT')?.format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        { title: 'scopecontrol', field: 'scopecontrol.serialnumber', },
        { title: 'brand', field: 'endoscope_serial.masterscope_library.endoscope_brand.brand', },
        { title: 'category', field: 'endoscope_serial.masterscope_library.endoscope_category.category', },
        { title: 'type', field: 'endoscope_serial.masterscope_library.scopetype', },
        { title: 'serial', field: 'endoscope_serial.serialnumber', },

        { title: 'totalresult', field: 'totalresult' },

        { title: 'cchpresult', field: 'cchpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.cchpresult)) },
        { title: 'ccresult', field: 'ccresult'},

        { title: 'lthpresult', field: 'lthpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.lthpresult)) },
        { title: 'ltresult', field: 'ltresult'},

        { title: 'fchpresult', field: 'fchpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.fchpresult)) },
        { title: 'fcresult', field: 'fcresult'},

        { title: 'fbhpresult', field: 'fbhpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.fbhpresult)) },
        { title: 'fbresult', field: 'fbresult'},

        { title: 'fvhpresult', field: 'fvhpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.fvhpresult)) },
        { title: 'fvresult', field: 'fvresult'},

        { title: 'vahpresult', field: 'vahpresult', type: 'numeric', render: rowData => Math.min(100, formatFloat(rowData.vahpresult)) },
        { title: 'varesult', field: 'varesult'},



    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="SC - Report"
                columns={columns}
                data={props.data}
                onRowClick={(event, rowData, togglePanel) => props.openReport(rowData.id_ms)}
                options={{
                    grouping: true,
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
            />
        </>
    );
}

export default withStyles(styles)(ScopeControlReportTable);
