import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DateSelect from "../components/DateSelect";
import Button from "@material-ui/core/Button";
import SearchAuto from "../components/SearchAuto";
import 'date-fns';
import GuideControlRawDataTable from "../components/GuideControlRawDataTable";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {useLocalStorage} from "../context/useLocalStorage";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function GuideControlDataPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const [selectedOrg, setSelectedOrg] = useLocalStorage("selectedOrg", null)
    const [selectedGc, setSelectedGc] = useLocalStorage("selectedGc", null)
    const [selectedScannerMessage, setSelectedScannerMessage] = useLocalStorage("selectedScannerMessage", null)

    const [reRenderGc, setReRenderGc] = useState(0)

    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth()-2);
    fromDateInitial.setDate(1);
    const tillDateInitial = new Date();

    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [tillDate, setTillDate] = useState(tillDateInitial);

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);


    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        flaskAPI.get('/api/data/gc/measurement', {
            params: {
                id_org: selectedOrg ? selectedOrg.id_org : '',
                id_gc: selectedGc? selectedGc.id_gc : '',
                from_date: fromDate ? fromDate : '',
                till_date: tillDate ? tillDate : '',
                scanner_message: selectedScannerMessage ? selectedScannerMessage.scanner_message : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {/* Column */}
                    <Grid container spacing={2} direction="column">
                        {/* Org -> GC */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="org_name"
                                    inputLabel="Select organization"
                                    noOptionsText="No organizations"
                                    value={selectedOrg}
                                    onChange={(newVal) => {
                                        setSelectedOrg(newVal)
                                        setSelectedGc(null)

                                        setReRenderGc(reRenderGc+1)
                                    }}
                                    apiUrl="/api/data/org/search"
                                />
                            </Grid>
                            <Grid item> <ArrowForwardIcon /> </Grid>

                            <Grid item>
                                <SearchAuto
                                    optionLabelName="serialnumber"
                                    key={reRenderGc}
                                    inputLabel="Select GuideControl"
                                    noOptionsText="No GuideControls"
                                    value={selectedGc}
                                    onChange={setSelectedGc}
                                    apiUrl={!selectedOrg ? "/api/data/gc/search" : ("/api/data/gc/search?id_org=" + selectedOrg.id_org)}
                                />
                            </Grid>

                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <DateSelect
                                    error={fromDateError}
                                    inputLabel="Date from"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateSelect
                                    error={tillDateError}
                                    inputLabel="Date till"
                                    value={tillDate}
                                    onChange={handleTillDateChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container direction="row" spacing={1} alignItems="flex-end">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="scanner_message"
                                    inputLabel="Scanner message"
                                    noOptionsText="No scanner message"
                                    value={selectedScannerMessage}
                                    onChange={setSelectedScannerMessage}
                                    apiUrl={"/api/data/gc/scannermessage/search"}
                                />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary"
                                        disabled={fromDateError || tillDateError}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <GuideControlRawDataTable data={data} loading={loading} />
            </Grid>
        </Grid>

    );
}